import React from "react";
import styled from "styled-components";
import Banner from "../components/Banner";
// import BannerRedesignVast from "../components/BannerRedesignVast";
import BannerRedesign from "../components/BannerRedesign";
import { useWindowSize } from "../components/CustomHooks/useWindowSize";
import SectionEzDivisorConsultation from "../components/ReUsables/SectionEzDivisorConsultation";
import SectionEzDivisorNewsLetter from "../components/ReUsables/SectionEzDivisorNewsLetter";
import SectionLatestBlogPosts from "../components/ReUsables/SectionLatestBlogPosts";
import SectionWithImage from "../components/ReUsables/SectionWithImage";
import Testimonials from "../components/ReUsables/Testimonials";
import {
  GlobalOptions,
  LastThreePostsType,
  ProjectsType,
  LabelsType,
  TestimonialsType,
} from "../shared/interfaces/graphql.interface";
import { RespContainer } from "../styles/common";
import { Colors, Devices } from "../styles/variables";

interface PageDataProps {
  pageContext: { data: any };
}

interface PageContext {
  testimonials: TestimonialsType;
  acf: {
    logo: { source_url: string };
    banner_title: string;
    banner_subtitle: string;
    banner_description: string;
    banner_image: {
      localFile: any;
      alt_text: string;
    };
  };
  projects: ProjectsType;
  title: string;
  content: string;
  globalOptions: GlobalOptions;
  lastThreePost: LastThreePostsType;
  labels: LabelsType;
}

const ourWork: React.FC<PageDataProps> = ({ pageContext, ...props }) => {
  let pageContextData: PageContext = pageContext.data;

  const getProjectAllLabels = (project) =>
    project.node.label?.map((projectLabel) =>
      pageContextData.labels.nodes.filter(
        (label) => label.wordpress_id === projectLabel
      )
    );

  const { width } = useWindowSize();
        
  return (
    <>
      {/* <Banner
        title={pageContextData.acf.banner_title}
        subtitle={pageContextData.acf.banner_subtitle}
        description={pageContextData.acf.banner_description}
        hideReadmore
        banner={pageContextData.globalOptions.ez_banner}
      /> */}

      <BannerRedesign
        title={pageContextData.acf.banner_title}
        title_2={pageContextData.acf.banner_title_2}
        subtitle={pageContextData.acf.banner_description}
        banner={pageContextData.acf.banner_image}
      />

      <ProjectsWrapper>
        <ProjectsInnerWrapper>
        {console.log("pageContextData",pageContextData)}
          {pageContextData.projects.map((project, idx) => (
            <GrayBG

              key={`project${idx}`}
            >
              <RespContainerHome
                style={{
                  overflowX: "hidden",
                }}
              >
                <SectionWithImage
                  section={{
                    image: project.node.acf.image,
                    title: project.node.title,
                    description: project.node.acf.short_description,
                  }}
                  reversed={idx % 2 !== pageContextData.projects.length % 2}
                  projectLabels={getProjectAllLabels(project)}
                  hideDots
                  lowerImage
                  noReadmore
                  showProjectButtons
                  projectButtonsLinks={{
                    path: `/our-work/${project.node.slug}`,
                    site: project.node.acf?.website?.url,
                  }}
                />
                {/* {idx % 2 == 0 && <ProjectDivisor />} */}
              </RespContainerHome>
            </GrayBG>
          ))}
        </ProjectsInnerWrapper>
      </ProjectsWrapper>

      <GrayBG>
        <Testimonials testimonials={pageContextData.testimonials} />
      </GrayBG>

      {/* <SectionEzDivisorNewsLetter
        section={pageContextData.globalOptions.ez_divisor_newsletter}
      /> */}

      <SectionLatestBlogPosts lastThreePost={pageContextData.lastThreePost} />
    </>
  );
};

export default ourWork;

const RespContainerHome = styled(RespContainer)`
  flex-direction: column;
`;

const ProjectDivisor = styled("div")`
  height: 3px;
  width: 100%;
  background: ${Colors.gray4};

  margin-bottom: 4px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 60px;
    margin-bottom: 260px;
  }
`;

const ProjectsWrapper = styled("div")`
  margin-top: 50px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const ProjectsInnerWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  background-color: ${Colors.gray8};

  & > div:first-child {
    padding-top: 26px;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    & > div:first-child {
      padding-top: 198px;
    }
  }
`;

const GrayBG = styled("div")`
  background-color: ${Colors.gray8};
`;
