import { Link } from "gatsby";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { FontelloIcon } from "../../styles/CommonComponents";

import * as StylingSectionWithImage from "../../styles/Reusables/StylingSectionWithImage";
import { useWindowSize } from "../CustomHooks/useWindowSize";

interface SectionWithImageProps {
  section: {
    image: { localFile: any; alt_text: string };
    title: string;
    subtitle?: string;
    description: string;
    button_title?: string;
  };
  reversed?: boolean;
  noReadmore?: boolean;
  halfOpacitySubtitle?: boolean;
  higherImage?: boolean;
  lowerImage?: boolean;
  hideDots?: boolean;
  projectLabels?: [
    [{ name: string; path: string; slug: string; wordpress_id: string }]
  ];
  showProjectButtons?: boolean;
  projectButtonsLinks?: { path: string; site: string };
}

const SectionWithImage: React.FC<SectionWithImageProps> = ({
  section,
  reversed,
  noReadmore,
  halfOpacitySubtitle,
  higherImage,
  lowerImage,
  projectLabels,
  hideDots,
  showProjectButtons,
  projectButtonsLinks,
}) => {
  const { width } = useWindowSize();

  const ContainerStyles = () => {
    let reverse = reversed ? { flexDirection: "row-reverse" } : {};
    let resize = higherImage ? { padding: "5.25rem 0" } : {};
    return { ...reverse, ...resize };
  };

  return (
    <StylingSectionWithImage.Container
      style={+width >= 1200 ? (ContainerStyles() as Object) : {}}
    >
      <StylingSectionWithImage.ImageWrapper
        style={
          +width >= 1200 ? (higherImage ? { margin: "-5.25rem 0" } : {}) : {}
        }
      >
        {reversed ? (
          <>
            {!hideDots && <StylingSectionWithImage.DotsReversed />}
            <StylingSectionWithImage.ImageReversed
              image={getImage(section.image?.localFile) as IGatsbyImageData}
              alt={section.image?.alt_text}
              // style={
              //   lowerImage ? { paddingTop: `4rem`, paddingBottom: `4rem` } : {}
              // }
            />
          </>
        ) : (
          <>
            <StylingSectionWithImage.Image
              image={getImage(section.image?.localFile) as IGatsbyImageData}
              alt={section.image?.alt_text}
              // style={
              //   lowerImage ? { paddingTop: `4rem`, paddingBottom: `4rem` } : {}
              // }
            />
            {!hideDots && <StylingSectionWithImage.Dots />}
          </>
        )}
      </StylingSectionWithImage.ImageWrapper>

      <StylingSectionWithImage.ImageMobileWrapper>
        <StylingSectionWithImage.ImageMobile
          image={getImage(section.image?.localFile) as IGatsbyImageData}
          alt={section.image?.alt_text}
          // style={{
          //   left: width > 960 ? (960 - width - 45) / 2 : -15,
          //   width: width > 960 ? width : `calc(100% + 30px)`,
          //   // width: width > 960 ? width : width,
          // }}
        />
      </StylingSectionWithImage.ImageMobileWrapper>

      <StylingSectionWithImage.Content>
        <StylingSectionWithImage.Title>
          {section.title}
        </StylingSectionWithImage.Title>

        <StylingSectionWithImage.Divisor />

        {section?.subtitle && (
          <StylingSectionWithImage.Subtitle
            style={halfOpacitySubtitle ? { opacity: 0.5 } : {}}
          >
            {section.subtitle}
          </StylingSectionWithImage.Subtitle>
        )}

        {projectLabels?.length > 0 && (
          <StylingSectionWithImage.LabelWrapper>
            {projectLabels.map(
              (item, idx) =>
                item && (
                  <StylingSectionWithImage.Label key={`label${idx}`}>
                    {item[0].name}{" "}
                  </StylingSectionWithImage.Label>
                )
            )}
          </StylingSectionWithImage.LabelWrapper>
        )}

        <StylingSectionWithImage.Description
          dangerouslySetInnerHTML={{ __html: section.description }}
        />

        <StylingSectionWithImage.Footer>
          {!noReadmore && (
            <StylingSectionWithImage.ReadMore to={"/services"}>
              <StylingSectionWithImage.ReadMoreText>
                Read More
              </StylingSectionWithImage.ReadMoreText>
              <FontelloIcon icon={"right-open"} />
            </StylingSectionWithImage.ReadMore>
          )}

          {section?.button_title && (
            <StylingSectionWithImage.Button to={"/contact"}>
              {section.button_title}
            </StylingSectionWithImage.Button>
          )}

          {showProjectButtons && (
            <StylingSectionWithImage.ProjectButtonsWrapper>
              <StylingSectionWithImage.CaseStudyButton
                to={projectButtonsLinks.path}
              >
                VIEW CASE STUDY
              </StylingSectionWithImage.CaseStudyButton>

              {projectButtonsLinks?.site && (
                <StylingSectionWithImage.WebsiteButton
                  href={projectButtonsLinks.site}
                  reversedTheme
                >
                  VISIT WEBSITE
                </StylingSectionWithImage.WebsiteButton>
              )}
            </StylingSectionWithImage.ProjectButtonsWrapper>
          )}
        </StylingSectionWithImage.Footer>
      </StylingSectionWithImage.Content>
    </StylingSectionWithImage.Container>
  );
};

export default SectionWithImage;
